<template>
  <div id="programPlace">
    <app-menu></app-menu>
    <admin-list-component
        :title="'Admin.menu.place' | staticTranslation"
        :add-text="'Admin.components.addPlace' | staticTranslation"
        edit-path="/admin/program/place/edit/"
        entity-name="stage"
        :get-name-function="getName"
        draggable="true"
    />
  </div>
</template>

<script>
import AdminMenu from "@/components/admin/AdminMenu";
import AdminListComponent from "@/components/admin/AdminListComponent";

export default {
  name: "adminSchedule",
  components: {
    'app-menu': AdminMenu,
    'admin-list-component': AdminListComponent
  },
  data() {
    return {
      stages: []
    }
  },
  methods: {
    getName: function (stage) {
      return stage.name
    }
  }
}
</script>

<style scoped>
#programPlace {
  background-color: white;
  min-height: 100vh;
  overflow: hidden;
}

</style>
